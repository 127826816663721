import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'
import qs from 'qs'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  timeout: 100000,
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // console.log('拦截Request' + JSON.stringify(config))
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    // if(sessionStorage.getItem('token')){
    //   config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
    // }
    const currProject = store.getters.currProject
    if (currProject && currProject.projectId) {
      config.headers['CURR-PROJECT-ID'] = currProject.projectId
    }

    if (config.method === 'get') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  (error) => {
    // console.log('拦截Error!' + JSON.stringify(error))
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    // console.log('拦截Response' + JSON.stringify(response))
    // if (response.config.responseType === 'arraybuffer') return response.data
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.returnStatus && res.returnStatus !== '0') {
      // message.error(res.message || '处理失败')
      return false
    } else {
      if (response.config.method != 'post') {
        // message.success(res.message || '处理成功')
      }
      return res
    }
  },
  (error) => {
    if(JSON.stringify(error.response.status) == 401){
      router.push({ path: '/login' })
    }
    // console.log('拦截Error!' + JSON.stringify(error))
    // message.error(error.message || '处理异常')
    return Promise.reject(error)
  }
)

export default service
