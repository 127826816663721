import { login, logout} from '@/api/account' //getUserInfo
import {setOidType,removeOidType,setOidList,removeOidList,setOid,removeOid, getToken, setToken, removeToken,setUserinfo,getUserinfo,removeUserinfo,setPaw,removePaw,setUser,removeUser,setCheckbox,removeCheckbox} from '@/utils/auth'

const user = {
  state: {
    user: '',
    status: '',
    code: '',
    token: getToken(),
    name: '',
    avatar: '',
    introduction: '',
    organizationId:'',
    organizationList:[],
    roles: [],
    perms: [],
    setting: {
      articlePlatform: []
    }
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ORGANID: (state, organizationId) => {
      state.organizationId = organizationId
    },
    SET_ORGANIDLIST: (state, organizationList) => {
      state.organizationList = organizationList
    },
    SET_PERMS: (state, perms) => {
      state.perms = perms
    }
  },

  actions: {
    // 用户名登录
    LoginByUsername({ commit }, userinfo) {
      return new Promise((resolve, reject) => {
        login(userinfo).then(response => {
          const token = response.data.accessToken
          commit('SET_TOKEN', token)
          setToken(token)
          setUserinfo(userinfo)
          if(userinfo.checkbox){
            setPaw(userinfo.password)
            setUser(userinfo.username)
            setCheckbox(true)
          }else{
            setUser(userinfo.username)
            removePaw()
            removeCheckbox()
          }
          resolve(response.data)
        }).catch(error => {
          reject(error)
         
        })
      })
    },
    Organization({ commit }, organizationId){
      commit('SET_ORGANID', organizationId)
      setOid(organizationId)
    },
    OrganizationList({ commit }, organizationList){
      commit('SET_ORGANIDLIST', organizationList)
      setOidList(organizationList)
    },
    OrganizationType({ commit }, organizationType){
      setOidType(organizationType)
    },
    // 获取用户信息
    // GetUserInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getUserInfo(state.token).then(response => {
    //       const data = response.data.data

    //       if (data.perms && data.perms.length > 0) { // 验证返回的perms是否是一个非空数组
    //         commit('SET_PERMS', data.perms)
    //       } else {
    //         reject('getInfo: perms must be a non-null array !')
    //       }

    //       commit('SET_ROLES', data.roles)
    //       commit('SET_NAME', data.name)
    //       commit('SET_AVATAR', data.avatar)
    //       commit('SET_INTRODUCTION', data.introduction)
    //       resolve(response)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 第三方验证登录
    // LoginByThirdparty({ commit, state }, code) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CODE', code)
    //     loginByThirdparty(state.status, state.email, state.code).then(response => {
    //       commit('SET_TOKEN', response.data.token)
    //       setToken(response.data.token)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(JSON.parse(getUserinfo())).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMS', [])
          removeUserinfo()
          removeToken()
          resolve()
          removeOidList()
          removeOid()
          removeOidType()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },

    // 动态修改权限
    ChangeRoles({ commit, dispatch }, role) {
      return new Promise(resolve => {
        commit('SET_TOKEN', role)
        setToken(role)
        getUserInfo(role).then(response => {
          const data = response.data
          commit('SET_ROLES', data.roles)
          commit('SET_PERMS', data.perms)
          commit('SET_NAME', data.name)
          commit('SET_AVATAR', data.avatar)
          commit('SET_INTRODUCTION', data.introduction)
          dispatch('GenerateRoutes', data) // 动态修改权限后 重绘侧边菜单
          resolve()
        })
      })
    }
  }
}

export default user
