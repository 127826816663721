import Cookies from 'js-cookie'

const TokenKey = 'X-Litemall-Admin-Token'
const password = 'Password'
const username = 'Username'
const checkboxif = 'Checkbox'
const Userinfo = 'userinfo'
const OrganizationId = 'organizationId'
const OrganizationList = 'organizationList'
const OrganizationType = 'organizationType'
export function getOidType() {
  return localStorage.getItem(OrganizationType)
}

export function setOidType(OIdList) {
  return localStorage.setItem(OrganizationType, OIdList)
}

export function removeOidType() {
  return localStorage.removeItem(OrganizationType)
}

export function getOidList() {
  return localStorage.getItem(OrganizationList)
}

export function setOidList(OIdList) {
  return localStorage.setItem(OrganizationList, JSON.stringify(OIdList))
}

export function removeOidList() {
  return localStorage.removeItem(OrganizationList)
}

export function getOid() {
  return localStorage.getItem(OrganizationId)
}

export function setOid(Oid) {
  return localStorage.setItem(OrganizationId, Oid)
}

export function removeOid() {
  return localStorage.removeItem(OrganizationId)
}

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}
export function getUserinfo() {
  return localStorage.getItem(Userinfo)
}

export function setUserinfo(info) {
  return localStorage.setItem(Userinfo, JSON.stringify(info))
}

export function removeUserinfo() {
  return localStorage.removeItem(Userinfo)
}

export function getPaw() {
  return localStorage.getItem(password)
}

export function setPaw(psw) {
  return localStorage.setItem(password, psw)
}

export function removePaw() {
  return localStorage.removeItem(password)
}

export function getUser() {
  return localStorage.getItem(username)
}

export function setUser(user) {
  return localStorage.setItem(username, user)
}

export function removeUser() {
  return localStorage.removeItem(username)
}
export function getCheckbox() {
  return localStorage.getItem(checkboxif)
}

export function setCheckbox(checkbox) {
  return localStorage.setItem(checkboxif, checkbox)
}

export function removeCheckbox() {
  return localStorage.removeItem(checkboxif)
}
