<template>
  <v-menu style="background-color: white" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span
        :class="!locked && (!show && show !== 0) ? 'placeholder' : 'inputvalue'"
        :style="locked || table ? `outline: 0px` : `outline: 2px solid #0288d1`"
        v-bind="attrs"
        v-on="on"
        >{{ show || show === 0 ? show : !locked ? '【请选择】' : '' }}</span
      >
    </template>
    <v-list v-if="items.length" max-height="300">
      <v-list-item v-for="(item, index) in items" :key="index" @click="change(item)">
        <v-list-item-title>{{ item && item.text ? item.text : item }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-if="!items.length" max-height="300">
      <v-list-item>
        <v-list-item-title>暂无可选项</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    items: {
      type: Array,
      default: () => ({}),
    },
    table: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    show() {
      let item = this.items.find((ele) => ele === this.value)
      if (item == null || item == undefined) {
        item = this.items.find((ele) => ele && this && ele.value === this.value)
      }
      if (item == null || item == undefined) {
        return ''
      }
      if (item && item.text) {
        return item.text
      }
      return item
    },
  },
  methods: {
    change(item) {
      const value = item && (item.value || item.value == 0) ? item.value : item && item.text ? item.text : item
      this.$emit('change', value)
    },
  },
}
</script>
<style scoped>
.placeholder {
  color: #e65100;
}
.inputvalue {
  color: #000000;
}
</style>
