import { extend } from 'vee-validate'
import { required, max, email, numeric, confirmed } from 'vee-validate/dist/rules'

// Add the required rule
extend('required', {
  ...required,
  message: '无内容或输入不全',
})

extend('select', {
  ...required,
  message: '请选择{_field_}。',
})

extend('phone', {
  validate: (value) => value.length === 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(value),
})

extend('max', {
  ...max,
  message: '{_field_}不能超过{length}位',
})

extend('email', {
  ...email,
  message: '邮箱格式不正确',
})
extend('numeric', {
  ...numeric,
  message: '请输入数字',
})
extend('confirmed', {
  ...confirmed,
  message: '{_field_}输入不一致',
})

extend('tinyRequired', {
  validate: (value) => {
    console.log(value)
    return value && value != '<p></p>'
  },
  message: '无内容或输入不全',
})

extend('treeRequired', {
  validate: (value) => {
    return value.children && value.children.length
  },
  message: '没有添加任何子节点',
})

extend('relation', {
  validate: (relations) => {
    const error = relations.some((row, i) => {
      return row.some((_, j) => {
        if (i !== j && i > 0 && j > 0) {
          if (relations[i][j] === 0) return true
          if (relations[i][j] != relations[j][i]) return true
        }
        return false
      })
    })
    return !error
  },
  message: '不正确或未设置的作用关系',
})

extend('selectSome', {
  params: ['hasSome'],
  validate: (value, param) => {
    console.log('selectSome', param)
    return param.hasSome !== '0'
  },
  message: '未选择任何一个项目',
})
