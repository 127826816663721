import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import home from '@/views/home/index'
// import Layout from '../layout/layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/my/project',
    hidden: true,
  },
  {
    path: '/login',
    component: () => import('@/views/login/login'),
    hidden: true,
  },
  {
    path: '/my',
    component: home,
    children: [
      {
        path: 'project',
        component: () => import('@/views/home/myProject/index'),
        name: 'myProject',
        meta: {
          title: '我的项目',
          icon: 'mdi-file',
          affix: true,
        },
      },
	  
	  {
	    path: 'tools',
	    component: () => import('@/views/home/tools/index'),
	    name: 'tools',
	    meta: {
	      title: '工具',
	      icon: 'mdi-toolbox',
	      affix: true,
	    },
	  },
	  
	  
      {
        path: 'recent',
        component: () => import('@/views/home/recent/index'),
        name: 'recent',
        meta: {
          title: '最近访问',
          icon: 'mdi-clock-time-five',
          affix: true,
        },
      },
      {
        path: 'favorites',
        component: () => import('@/views/home/favorites/index'),
        name: 'favorites',
        meta: {
          title: '收藏夹',
          icon: 'mdi-star',
          affix: true,
        },
      },
      {
        path: 'review',
        component: () => import('@/views/home/review/index'),
        name: 'review',
        meta: {
          title: '审阅',
          icon: 'mdi-account-edit',
          affix: true,
        },
      },
      {
        path: 'team',
        component: () => import('@/views/home/teamManage/index'),
        name: 'team',
        meta: {
          title: '团队管理',
          icon: 'mdi-account-edit',
          affix: true,
        },
        hidden: true,
      },
    ],
  },
  {
    path: '/triz/:projectId',
    name: 'triz',
    component: () => import('@/views/triz/index'),
    meta: { title: 'Triz' },
  },
  {
    path: '/new_triz/:projectId',
    name: 'new_triz',
    component: () => import('@/views/new_triz/index'),
    meta: { title: 'Triz' },
  },
  
  
  {
    path: '/one_triz/functional',
    name: 'functional',
    component: () => import('@/views/one_triz/functional'),
    meta: { title: 'Triz_功能分析' },
  },
  {
    path: '/one_triz/CausalChain',
    name: 'CausalChain',
    component: () => import('@/views/one_triz/CausalChain'),
    meta: { title: 'Triz_因果链分析' },
  },
  
  {
    path: '/one_triz/FunctionalCropping',
    name: 'FunctionalCropping',
    component: () => import('@/views/one_triz/FunctionalCropping'),
    meta: { title: 'Triz_功能裁剪' },
  },
  
  {
    path: '/one_triz/fos',
    name: 'fos',
    component: () => import('@/views/one_triz/fos'),
    meta: { title: 'Triz_功能导向搜索（FOS）' },
  },
  
  {
    path: '/one_triz/resolvingConflicts',
    name: 'resolvingConflicts',
    component: () => import('@/views/one_triz/resolvingConflicts'),
    meta: { title: 'Triz_解决矛盾的创新原理或技术' },
  },
  
  {
    path: '/one_triz/StandardSolution',
    name: 'StandardSolution',
    component: () => import('@/views/one_triz/StandardSolution'),
    meta: { title: 'Triz_标准解' },
  },
  {
    path: '/one_triz/science',
    name: 'science',
    component: () => import('@/views/one_triz/science'),
    meta: { title: 'Triz_科学效应库' },
  },
  {
    path: '/one_triz/inventive',
    name: 'inventive',
    component: () => import('@/views/one_triz/inventive'),
    meta: { title: 'Triz_发明原理' },
  },
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes,
  mode: 'hash',
})

export default router
