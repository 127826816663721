import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

import vuetify from '@/plugins/vuetify'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
  context: {
    vuetify: new Vuetify({}),
  },
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})

//导入通用样式
import '../components/Tree/src/styles/index.scss'
import ZmTreeOrg from '../components/Tree/src/index'
Vue.use(ZmTreeOrg)
