var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    {
      [_vm.classNameActive]: _vm.enabled,
      [_vm.classNameDragging]: _vm.dragging,
      [_vm.classNameDraggable]: _vm.draggable,
    },
    _vm.className,
  ],style:(_vm.style),on:{"mousedown":_vm.elementMouseDown,"touchstart":_vm.elementTouchDown}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }