<template>
  <span :style="locked || table ? `outline: 0px` : `outline: 2px solid #0288d1`">
    <template v-if="locked">{{ value }}</template>
    <v-textarea
      :placeholder="placeholder"
      v-if="!locked"
      :value="value"
      dense
      rows="1"
      auto-grow
      @change="input"
      :maxlength="max"
    ></v-textarea>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    table: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: 2000
    },
    placeholder: {
      type: String,
      default: '【请输入】'
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value)
    },
  },
}
</script>
