import Vue from 'vue'
import Vuex from 'vuex'
import storage from '../utils/storage'
import getters from './getters'
import { getTriz, saveTriz, insertReview, findReview, findRevision } from '../api/triz'

import user from './modules/user'
import localforage from 'localforage'

Vue.use(Vuex)

const defaultState = () => ({
  show: storage.get('show'),
  title: storage.get('title') || '',
  msgPool: [], // 消息队列
  scrollIndex: '0-0', // 当前滚动
  locked: true,
  projectId: '',
  version: 1,
  dirty: false,
  triz: null,
  trizMutations: [],
  mutationIndex: 0,
  contentKey: new Date().getTime(),
  remarks: [],
  help: '',
  drawerRight: false,
})

const store = new Vuex.Store({
  state: defaultState,
  mutations: {
    resetTriz(state) {
      state.scrollIndex = '0-0' // 当前滚动
      state.locked = true
      state.projectId = ''
      state.dirty = false
      state.triz = null
      state.trizMutations = []
      state.mutationIndex = 0
      state.contentKey = new Date().getTime()
      state.remarks = []
    },
    message(state, step) {
      state.msgPool.push(step)
      //将消息添加到消息列表
      setTimeout(() => {
        state.msgPool.shift()
      }, step.time)
      //清除消息
    },
    switchProject(state, projectId) {
      state.projectId = projectId
    },
    updateVersion(state, version) {
      state.version = version
    },
    intersect(state, index) {
      state.scrollIndex = index
    },
    relationGraphData(state, data) {
      state.relationGraphData = data
    },
    lockTriz(state) {
      state.locked = !state.locked
    },
    do(state, data) {
      this.commit('setMutations', data)
      localforage.setItem(`content-${state.projectId}`, data)
      localforage.setItem(`key-${state.projectId}`, state.version + 1)
      this.commit('dirty', true)
      console.log('do', state.trizMutations)
    },
    undo(state) {
      state.mutationIndex--
      state.triz = JSON.parse(JSON.stringify(state.trizMutations[state.mutationIndex]))
      localforage.setItem(`content-${state.projectId}`, state.triz)
      localforage.setItem(`key-${state.projectId}`, state.version + 1)
    },
    redo(state) {
      state.mutationIndex++
      state.triz = JSON.parse(JSON.stringify(state.trizMutations[state.mutationIndex]))
      localforage.setItem(`content-${state.projectId}`, state.triz)
      localforage.setItem(`key-${state.projectId}`, state.version + 1)
    },
    updateTriz(state, data) {
		console.log('updateTriz',data)
	  if(data.clipResolves && data.clipResolves.length>0 && data.clips && data.clips.length>0){
		  if(!(data.clips[0].clipResolves && data.clips[0].clipResolves.length>0)){
			  data.clips[0].clipResolves=data.clipResolves
		  }
	  }
	  if(!data.reasonsResolves){
		  data.reasonsResolves=[]
	  }
      state.triz = data
    },
    setMutations(state, data) {
      if (state.trizMutations.length > 1 && state.mutationIndex < state.trizMutations.length - 1) {
        state.trizMutations = state.trizMutations.slice(0, state.mutationIndex + 1)
      }
      state.trizMutations.push(JSON.parse(JSON.stringify(data)))
      if (state.trizMutations.length > 20) {
        state.trizMutations.shift()
      }
      state.mutationIndex = state.trizMutations.length - 1
    },
    setRemarks(state, remarks) {
      state.remarks = remarks
    },
    dirty(state, dirty) {
      state.dirty = dirty
    },
    changeHelp(state, help) {
      state.help = help
    },
    drawerRight(state, drawerRight) {
      state.drawerRight = drawerRight
    },
  },
  actions: {
    async getTriz({ state }, projectId) {
      return await getTriz(projectId)
    },
    saveTriz({ state, commit }, { projectId, version }) {
      const currTriz = JSON.parse(JSON.stringify(state.triz))
      currTriz.relationGraphData.nodes.forEach((node) => {
        node.flated = false
      })
      const res =  saveTriz(projectId, currTriz, version)
      return res
    },
    insertReview({ state }, data) {
      return insertReview(data)
    },
    findReview({ state }, reviewId) {
      return findReview(reviewId)
    },
    findRevision({ state }, data) {
      return findRevision(data)
    },
  },
  modules: {
    user,
  },
  getters,
})

export default store
