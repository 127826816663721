import request from '@/utils/request'

// 获取Triz内容
export function getTriz(projectId) {
  return request({
    url: `project/select/?projectId=${projectId}`,
    method: 'get',
  })
}

// 保存Triz内容
export function saveTriz(projectId, data, version) {
  return request({
    url: `project/save/${projectId}`,
    method: 'put',
    data: { projectId, jsonContent: data, htmlContent: '', version },
  })
}

// 提交批注
export function insertReview(data) {
  return request({
    url: 'project/insertReview',
    method: 'post',
    data,
  })
}

// 查询批注
export function findReview(reviewId) {
  return request({
    url: `/user/project/selectReviewComment/${reviewId}`,
    method: 'get',
  })
}

// 查询版本
export function findRevision(data) {
  return request({
    url: `project/selectProjectResume/version/${data.projectId}`,
    method: 'get',
    params:{revisionNumber: data.revisionNumber}
  })
}
