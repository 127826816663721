<template>
  <vv-provider immediate :skip-if-empty="false" :rules="rules" :name="id" :vid="id" :id="id">
    <slot></slot>
  </vv-provider>
</template>

<script>
export default {
  props: {
    rules: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
  },
}
</script>
