<template>
  <v-menu style="background-color: white" offset-y :close-on-content-click="false" :close-on-click="true">
    <template v-slot:activator="{ on, attrs }">
      <span
        :class=" (!show && show !== 0) ? 'placeholder' : 'inputvalue'"
        :style=" table ? `outline: 0px` : `outline: 2px solid #0288d1`"
        v-bind="attrs"
        v-on="on"
        >{{ show ? show :  '【请选择】'  }}</span
      >
    </template>
    <v-list v-if="items.length" max-height="300">
      <v-list-item v-for="(item, index) in items" :key="index" @click="change">
        <v-checkbox :value="item.value" v-model="value" @change="change"></v-checkbox>
		<v-tooltip right>
			<template v-slot:activator="{ on, attrs }">
				<span v-bind="attrs" v-on="on">
					<v-list-item-title>{{ item.text ? item.text : item }}</v-list-item-title>
				</span>
			</template>
			<span >{{ item.tooltip ?item.tooltip:item.text}}</span>
		</v-tooltip>
        
      </v-list-item>
    </v-list>
    <v-list v-if="!items.length" max-height="300">
      <v-list-item>
        <v-list-item-title>暂无可选项</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    items: {
      type: Array,
      default: () => ({}),
    },
    table: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  computed: {
    show() {
      let item = this.value.map(value => this.items.find((ele) => ele.value === value))
      if (!item == null || !item.length) {
        return ''
      }
      if (item[0].text) {
        return item.map(ele => ele.text).join(',')
      }
      return item.join(',')
    },
  },
  methods: {
    change() {
      this.$emit('change', this.value)
    },
  },
}
</script>
<style scoped>
.placeholder {
  color: #1976d2;
}
.inputvalue {
  color: rgba(0, 0, 0, 0.6);
}
</style>
