import request from '@/utils/request'

export function login(data){
  return request({
    url: 'auth/login',
    method: 'post',
    data
  })
}
//修改密码

export function updatePsword(data){
  return request({
    url: 'user/password/update',
    method: 'post',
    data
  })
}
export function selectUserName(userName) {
  return request({
    url: '/auth/selectUserName/' + userName,
    method: 'post',
  })
}
export function logout(data) {
  return request({
    url: 'user/logout',
    method: 'post',
    data:data
  })
}

export function showOrganization() {
  return request({
    url: 'user/organizationRelation/showOrganization/0',
    method: 'get',
  })
}
export function getshopList() {
  return request({
    url: 'shop/getMyShop',
    method: 'get'
  })
}
export function getUserinfo(id) {
  return request({
    url: 'userinfo/getEmpolyeeFromDepartmentId/' + id,
    method: 'get'
  })
}
export function classificationchage() {
  return request({
    url: 'my-account/ceshi',
    method: 'get',
  })
}

export function resetUsername(data) {
  return request({
    url: 'my-account/username',
    method: 'put',
    data: data
  })
}

export function resetPassword(data) {
  return request({
    url: 'my-account/password',
    method: 'put',
    data: data
  })
}

export function showTools(toolsType) {
  return request({
    url: 'tools/admin/getTools/'+toolsType,
    method: 'get',
  })
}
//新增工具
export function insertTools(data){
  return request({
    url: 'tools/create/insertTools',
    method: 'post',
    data
  })
}
//删除工具
export function delTools(data) {
  return request({
    url: 'tools/deleteTools/',
    method: 'delete',
	data
  })
}