import request from '@/utils/request'
let that = this
//新增项目/文件夹
export function addProject(data){
  return request({
    url: 'project/create/'+data.organizationId,
    method: 'post',
    data
  })
}
//修改我的项目
export function updateProject(data){
  return request({
    url: 'project/update/'+data.projectId,
    method: 'put',
    data
  })
}
//获取我的项目列表
export function getProject(data) {
  return request({
    url: 'user/project/parent/'+data.organizationId+'?sort=fileType,asc&sort=project.updateTime,desc&page='+data.pageNumber+'&size='+data.pageSize+'&isMyManage='+data.isMyManage,
    method: 'get'
  })
}
//删除项目/文件夹
export function deleteProject(data) {
  return request({
    url: 'user/project/deleteFolderOrProject/' + data.id,
    method: 'delete',
  })
}
//删除项目成员
export function deleteProjectTeam(data) {
  return request({
    url: 'project/deleteProjectUser/' + data.id,
    method: 'delete',
  })
}

export function Relation(id) {
  return request({
    url: 'user/project/update/collection/'+ id,
    method: 'put',
  })
}
//获取收藏项目
export function getCollection(data) {
  return request({
    url: 'user/project/collection/'+data.organizationId+'?page='+data.pageNumber+'&size='+data.pageSize,
    method: 'get'
  })
}
//获取移动文件夹逐级目录
export function getshowFolder(data) {
  return request({
    url: 'user/project/showFolder/'+data.organizationId+'?pId='+data.id,
    method: 'get'
  })
}
//移动文件夹
export function putMoveFolder(data) {
  return request({
    url: 'user/project/moveFolder',
    method: 'put',
    data
  })
}
//获取文件夹下级目录
export function getProjectChild(data) {
  return request({
    url: 'user/project/'+data.id+'/child?sort=fileType,asc&page='+data.pageNumber+'&size='+data.pageSize,
    method: 'get',
  })
}

export function updataFilte(data) {
  return request({
    url: 'file',
    method: 'post',
    data: data
  })
}

export function serachProject(data) {
  return request({
    url: 'user/project/find/'+data.organizationId+'?sort=fileType,asc&page='+data.pageNumber+'&size='+data.pageSize,
    method: 'post',
    data: data
  })
}
//最近访问
export function getZjProject(data) {
  return request({
    url: 'user/project/userAccessHistory/'+data.organizationId+'?sort=userAccessHistory.updateTime,desc&page='+data.pageNumber+'&size='+data.pageSize,
    method: 'get',
  })
}

//提交审阅 /project/insertReviewUser
export function insertReviewUser(data) {
  return request({
    url: 'project/insertReviewUser',
    method: 'post',
    data: data
  })
}
//审阅列表 user/project/findReviewUser
export function getFindReviewUser(data) {
  return request({
    url: 'user/project/findReviewUser/'+data.organizationId+'?sort=updateTime,asc&page='+data.pageNumber+'&size='+data.pageSize,
    method: 'post',
    data:data
  })
}
export function getFindReviewUserCount(data) {
  return request({
    url: 'user/project/findReviewUserCount/'+data.organizationId,
    method: 'post',
    data:data
  })
}
//审阅人列表
export function getOrganizationRelation(data) {
  return request({
    url: 'user/organizationRelation/select/'+data.organizationId,
    method: 'get'
  })
}
//撤销审阅
export function deleteReviewUser(id) {
  return request({
    url: 'project/deleteReviewUser/'+id,
    method: 'delete'
  })
}
//获取员工列表
export function getProjectUser(data) {
  return request({
    url: 'user/project/findProjectUser/'+data.projectId+'?page='+data.pageNumber+'&size='+data.pageSize,
    method: 'get'
  })
}
//获取成员权限列表
export function getProjectRole() {
  return request({
    url: 'user/project/findProjectRole',
    method: 'get'
  })
}
//修改成员权限列表
export function updateProjectUser(data) {
  return request({
    url: 'project/updateProjectUser',
    method: 'put',
    data:data
  })
}
//添加项目成员
export function addProjectUser(data) {
  return request({
    url: 'project/insertProjectUser',
    method: 'post',
    data:data
  })
}
//项目人员列表
export function getProjectStaff(projectId) {
  return request({
    url: 'user/organizationRelation/select/all/'+ projectId,
    method: 'get'
  })
}
//组织注册
export function register(url,data) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}
export function registerGet(url,data) {
  return request({
    url: url,
    method: 'get',
    data: data
  })
}