<template>
  <v-menu style="background-color: white" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span
        :class="(!show && show !== 0) ? 'placeholder' : 'inputvalue'"
        :style=" table ? `border: none` : `border-bottom: 2px solid #0288d1`"
        v-bind="attrs"
        v-on="on"
        >{{ show || show === 0 ? show : '【请选择】' }}</span
      >
    </template>
    <v-list v-if="items.length" max-height="300">
      <v-list-item v-for="(item, index) in items" :key="index" @click="change(item)" >
		  
        <v-list-item-title  v-if="!(item && item.tooltip)">{{ item && item.text ? item.text : item }}</v-list-item-title>
		
		<v-tooltip right v-if="item && item.tooltip">
			<template v-slot:activator="{ on, attrs }">
				<span v-bind="attrs" v-on="on">
					  <v-list-item-title>{{ item && item.text ? item.text : item }}</v-list-item-title>
				</span>
			</template>
			<div style="width: 200px;">
				注：
				<span>{{item.tooltip}}</span>
			</div>
			<div style="width: 200px;">
				例：
				<span>{{item.case}}</span>
			</div>
			
		</v-tooltip>
		
      </v-list-item>
	  
    </v-list>
    <v-list v-if="!items.length" max-height="300">
      <v-list-item>
        <v-list-item-title>暂无可选项</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    items: {
      type: Array,
      default: () => ({}),
    },
    table: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  computed: {
    show() {
      let item = this.items.find((ele) => ele === this.value)
      if (item == null || item == undefined) {
        item = this.items.find((ele) => ele && this && ele.value === this.value)
      }
      if (item == null || item == undefined) {
        return ''
      }
      if (item && item.text) {
        return item.text
      }
      return item
    },
  },
  methods: {
    change(item) {
      const value = item && (item.value || item.value == 0) ? item.value : item && item.text ? item.text : item
      this.$emit('change', value)
    },
  },
}
</script>
<style scoped>
.placeholder {
  color: #1976d2;
}
.inputvalue {
  color: rgba(0, 0, 0, 0.6);
}
</style>
