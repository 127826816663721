<template>
  <v-app style="font-family: 'Noto Serif SC', serif">
    <v-main>
      <router-view :key="key"> </router-view>
    </v-main>
    <Message />
  </v-app>
</template>

<script>
import Message from './components/Message.vue'
export default {
  name: 'App',
  components: {
    Message: Message,
  },
  data() {
    return {
    }
  },
  computed: {
    key() {
      return this.$route.path + Math.random()
    },
  },
}
</script>
<style lang="scss">
.zanwu{
    width: 200px;
    text-align: center;
    margin: 100px auto;
    color: #999;
    div{
      width: 100%;
      text-indent:50px;
    }
}
</style>
