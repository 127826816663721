<template>
	<v-app class="app" id="app">
		<v-alert dense class="alert" :value="tipsInfo" :type="tipsInfoType">{{ tipsInfoContent }}</v-alert>
		<v-navigation-drawer fixed v-model="drawer" width="160" height="100%" overlay-color="#f5f5f5" clipped app>
			<v-list dense nav>
				<router-link v-for="item in navigationList" :key="item.title" :to="{ path: item.path }"
					style="text-decoration: none" v-show="!item.hidden">
					
					<v-list-item link class="v_list_item" :class="fullPath == item.name ? 'v_selectItem' : ''"
						style="margin-bottom: 10px" v-show="!(item.name == 'review' &&  oidType == 0)">
						<span v-if="item.name=='review'  && unreadCount > 0" class="unread-bubble">{{ unreadCount }}</span>
						<v-list-item-icon class="v_icon_name">
							<v-icon :color="fullPath == item.name ? '#3f81c1' : '#333'"
								size="16">{{ item.meta.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title class="v_title_name"
								:style="fullPath == item.name ? 'color:#3f81c1' : ''">{{
                item.meta.title
              }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</router-link>
			</v-list>
		</v-navigation-drawer>

		<v-app-bar clipped-left color="#3f81c1" flat app class="app_bar">
			<img src="../../assets/logo.png" />
			<v-select class="app-top-select" :items="autocomplete"
				:item-text="(item) => `${item.organization.organizationName}`"
				:item-value="(item) => `${item.organization.organizationId}`" color="#fff" filled v-model="selectValue"
				background-color="#0955AC" @change="switchOrganization" dense solo></v-select>
			<v-menu offset-y tile>
				<template v-slot:activator="{ on, attrs }">
					<div class="v_menu_btn" style="color: #fff" dark v-bind="attrs" v-on="on">
						<v-icon class="v_message" color="#fff">mdi-bell-outline</v-icon>
						{{ nickName }}
						<v-icon color="#fff">mdi-menu-down</v-icon>
					</div>
				</template>
				<v-list>
					<v-list-item link @click="editPswshow">
						<v-list-item-title style="cursor: pointer">修改密码</v-list-item-title>
					</v-list-item>
					<v-list-item link>
						<v-list-item-title @click="loginOut" style="cursor: pointer">退出登录</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-avatar class="v_avatar"><img src="../../assets/avatar.png" /></v-avatar>
		</v-app-bar>
		<!-- 给应用提供合适的间距 -->
		<v-container fluid class="app-content">
			<div class="app-maun-content">
				<router-view v-if="isRouterAlive" />
			</div>
		</v-container>
		<!-- 修改密码弹框 -->
		<v-row justify="center">
			<v-dialog v-model="editPswDialog" max-width="600px">
				<v-card>
					<v-card-title>
						<span class="text-h5">修改密码</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="formPasword" v-model="valid" lazy-validation>
								<v-row>
									<v-col cols="12" style="padding: 12px 12px 0">
										<v-text-field label="原密码 *" outlined v-model="updatePsw.oldPassword"
											:rules="rules" required></v-text-field>
									</v-col>
									<v-col cols="12" style="padding: 0 12px">
										<v-text-field label="新密码 *" outlined :rules="rules"
											v-model="updatePsw.newPassword" required></v-text-field>
									</v-col>
									<v-col cols="12" style="padding: 0 12px">
										<v-text-field label="再次确认新密码 *" outlined :rules="rules"
											v-model="updatePsw.verifyPassword" required></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="#ccc darken-4" @click="editPswDialog = false"> 关闭 </v-btn>
						<v-btn color="blue darken-2" style="color: #fff" @click="editPsw"> 确认 </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</v-app>
</template>

<script>
    import Vue from 'vue'
	import {
		getUser,
		getOidType,
		getOid
	} from '@/utils/auth'
	import {
		showOrganization,
		updatePsword
	} from '@/api/account'
	import { getFindReviewUserCount } from '@/api/project'
	export default {
		data: () => ({
			unreadCount: parseInt(localStorage.getItem('unreadCount')) || 0, 
			valid: true,
			tipsInfo: false,
			tipsInfoType: 'success',
			tipsInfoContent: '',
			drawer: true,
			isRouterAlive: true,
			values: '',
			fullPath: '', //当前路由
			autocomplete: [],
			oidType: getOidType() ? getOidType() : '0',
			name: getUser(),
			nickName: '',
			selectValue: getOid(),
			navigationList: [],
			updatePsw: {
				oldPassword: '',
				newPassword: '',
				verifyPassword: '',
			},
			editPswDialog: false,
			rules: [(value) => !!value || '请输入'],
		}),
		watch: {
			unreadCount(newValue) {
			localStorage.setItem('unreadCount', newValue);
			},
		},
		created() {
			this.$router.options.routes.forEach((e) => {
				if (e.path === '/my') {
					// console.log(e.children)
					this.navigationList = e.children
				}
			})
			if (!getOid()) {
				this.showOrganizationlist()
			}
			if (getOid()) {
				this.getNikName()
				this.Organization()
			}
			this.fullPath = this.$router.currentRoute.name

			Vue.prototype.$addStorageEvent = function (type, key, data) {
				if (type === 1) {
					// 创建一个StorageEvent事件
					var newStorageEvent = document.createEvent('StorageEvent')
					const storage = {
					setItem: function (k, val) {
						localStorage.setItem(k, val)
						// 初始化创建的事件
						newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
						// 派发对象
						window.dispatchEvent(newStorageEvent)
					}
					}
					return storage.setItem(key, data)
				} else {
					// 创建一个StorageEvent事件
					var newStorageEvent1 = document.createEvent('StorageEvent')
					const storage = {
					setItem: function (k, val) {
						sessionStorage.setItem(k, val)
						// 初始化创建的事件
						newStorageEvent1.initStorageEvent('setItem', false, false, k, null, val, null, null)
						// 派发对象
						window.dispatchEvent(newStorageEvent1)
					}
					}
					return storage.setItem(key, data)
				}
			}
			this.getFindReviewUserList();
		},
		mounted: function() {},
		methods: {
			//获取审阅未读
			getFindReviewUserList() {
				getFindReviewUserCount({
					organizationId: getOid(),
				})
				.then((res) => {
					console.log(res.data)
					this.$addStorageEvent(1, "unreadCount", res.data.unreadCount_sy);//同步
				})
				.catch(() => {})
			},
			getNikName(){
				let that = this
				showOrganization()
					.then((response) => {
						let data = response.data
						that.nickName = data[0].user.nickName
					})
					.catch(() => {})
			},
			//获取组织信息
			showOrganizationlist() {
				let that = this
				showOrganization()
					.then((response) => {
						let data = response.data
						that.nickName = data[0].user.nickName
						data.forEach((res) => {
							if (res.organization.organizationType == 0) {
								that.autocomplete = data
								that.selectValue = res.organization.organizationId
								that.$store.dispatch('Organization', res.organization.organizationId)
								that.$store.dispatch('OrganizationType', res.organization.organizationType)
								that.$store.dispatch('OrganizationList', response.data)
							}
						})
					})
					.catch(() => {})
			},
			//展示弹框
			editPswshow() {
				this.editPswDialog = true
				this.$nextTick(function() {
					this.$refs.formPasword.resetValidation()
				})
			},
			//修改密码
			editPsw() {
				this.$refs.formPasword.validate()
				if (!(this.updatePsw.oldPassword && this.updatePsw.newPassword && this.updatePsw.verifyPassword)) {
					return false
				} else if (this.updatePsw.newPassword != this.updatePsw.verifyPassword) {
					this.tipsInfo = true
					this.tipsInfoType = 'error'
					this.tipsInfoContent = '两次输入的新密码不一致'
					setTimeout(() => {
						this.tipsInfo = false
					}, 2000)
					return false
				}
				let data = {
					oldPassword: this.updatePsw.oldPassword,
					newPassword: this.updatePsw.newPassword,
				}
				updatePsword(data)
					.then((response) => {
						this.tipsInfo = true
						this.tipsInfoType = 'success'
						this.tipsInfoContent = '修改成功'
						setTimeout(() => {
							this.tipsInfo = false
							this.$router.push({
								path: '/'
							})
						}, 2000)
					})
					.catch((error) => {
						let err = error.response.data.message
						this.tipsInfo = true
						this.tipsInfoType = 'error'
						this.tipsInfoContent = err
						setTimeout(() => {
							this.tipsInfo = false
						}, 2000)
					})
				this.editPswDialog = false
			},
			//切换组织
			switchOrganization(e) {
				let that = this
				this.$store.getters.organizationList.forEach((res) => {
					if (res.organization.organizationId == e) {
						that.oidType = res.organization.organizationType
						that.$store.dispatch('OrganizationType', res.organization.organizationType)
					}
				})
				this.$store.dispatch('Organization', e)
				this.isRouterAlive = false
				this.$router.push({
					path: '/my/project'
				})
				setTimeout(() => {
					this.isRouterAlive = true
				}, 50)
			},
			//获取组织信息
			Organization() {
				let that = this
				showOrganization()
					.then((response) => {
						that.autocomplete = response.data
						that.$store.dispatch('OrganizationList', response.data)
					})
					.catch(() => {})
			},
			loginOut() {
				this.$store
					.dispatch('LogOut', {})
					.then(() => {
						this.$router.push({
							path: '/login'
						})
					})
					.catch((error) => {
						let err = error.response.data
						this.tipsInfo = true
						this.tipsInfoType = 'error'
						this.tipsInfoContent = err.message
						setTimeout(() => {
							this.tipsInfo = false
						}, 2000)
					})
			},
		},
	}
</script>
<style lang="scss">
	.unread-bubble {
		background-color: red;
		color: white;
		padding: 0px 7px;
		border-radius: 50%;
		position: absolute;
		right: 23px;
	}
	.alert {
		position: fixed;
		top: 30px;
		width: auto;
		align-self: center;
		margin: 0 auto;
		z-index: 9999;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	.v_menu_btn {
		position: absolute;
		right: 80px;
	}

	.v_avatar {
		width: 32px;
		height: 32px;
		position: absolute;
		right: 20px;
	}

	.v_message {
		margin-right: 20px;
	}

	.v_list_item:hover {
		background: rgba(24, 103, 192, 0.12);
		border-radius: 25px;
	}

	.v_list_item::before {
		display: none;
	}

	.v_icon_name {
		margin-right: 16px !important;
		margin-left: 16px;
	}

	.v_selectItem {
		background: rgba(24, 103, 192, 0.12);
		border-radius: 25px !important;
	}

	.v_title_name {
		font-size: 14px !important;
		color: #333;
		font-weight: bold !important;
	}

	.v-application--wrap {
		min-height: calc(100vh - 64px) !important;
	}

	.app-top-select {
		max-width: 120px;
		border-radius: 20px;
		position: relative;
		top: 5px;
		left: 10px;
		height: 50px;
		font-size: 12px;

		.v-input__control {
			.v-input__slot {
				box-shadow: none !important;

				.v-icon {
					color: #fff;
				}
			}
		}
	}

	.app_bar {
		.v-select.v-input--dense .v-select__selection--comma {
			color: #fff !important;
		}
	}

	.app-content {
		min-height: calc(100vh - 64px);
		background: #f8f8f8;
	}

	.app-maun-content {
		width: 100%;
		height: 100%;
		background: #fff;
		border-radius: 2px;
		box-shadow: 0 0 4px #f0f0f0;
	}
</style>