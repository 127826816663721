const storage={
    set(key,value){
       // eslint-disable-next-line no-undef
       sessionStorage.setItem(key,JSON.stringify(value))   
    },
    get(key){
    // eslint-disable-next-line no-undef
    return JSON.parse(sessionStorage.getItem(key))
    },
    remove(key){
        sessionStorage.removeItem(key)
    }
    }
    export default storage