<template>
  <v-menu style="background-color: white" offset-y :close-on-content-click="false" :close-on-click="true">
    <template v-slot:activator="{ on, attrs }">
      <span
        :class="!locked && (!show && show !== 0) ? 'placeholder' : 'inputvalue'"
        :style="locked || table ? `outline: 0px` : `outline: 2px solid #0288d1`"
        v-bind="attrs"
        v-on="on"
        >{{ show ? show : !locked ? '【请选择】' : '' }}</span
      >
    </template>
    <v-list v-if="items.length" max-height="300">
      <v-list-item v-for="(item, index) in items" :key="index" @click="change">
        <v-checkbox :value="item.value" v-model="value" @change="change"></v-checkbox>
        <v-list-item-title>{{ item.text ? item.text : item }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-if="!items.length" max-height="300">
      <v-list-item>
        <v-list-item-title>暂无可选项</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    items: {
      type: Array,
      default: () => ({}),
    },
    table: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    show() {
      console.log('show')
      let item = this.value.map(value => this.items.find((ele) => ele.value === value))
      if (!item == null || !item.length) {
        return ''
      }
      if (item[0].text) {
        return item.map(ele => ele.text).join(',')
      }
      return item.join(',')
    },
  },
  methods: {
    change() {
      this.$emit('change', this.value)
    },
  },
}
</script>
<style scoped>
.placeholder {
  color: #e65100;
}
.inputvalue {
  color: #000000;
}
</style>
