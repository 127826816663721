<template>
	<div ref="zm-tree-org" class="zm-tree-org" style="padding:15px 0">
		<div ref="zoom" class="zoom-container" :style="treeStyle" @wheel="zoomWheel">
			<zm-draggable :x="left" :y="top" ref="divx" id="divx" :class="{ dragging: autoDragging }" @dragging="onDrag"
				@dragstop="onDragStop" :draggable="draggable" :drag-cancel="dragCancel">
				<div ref="tree-org" class="tree-org" :class="{ horizontal, collapsable }">
					<tree-org-node :data="data" :props="keys" :horizontal="horizontal" :label-style="labelStyle"
						:collapsable="collapsable" :render-content="renderContent" :label-class-name="labelClassName"
						v-nodedrag.l.t="nodeargs" @on-expand="handleExpand" @on-logic-change="handleLogicChange"
						@on-node-click="handleDblclick" @on-node-mouseenter="nodeMouseenter" @on-node-mouseleave="nodeMouseleave"
						@on-node-contextmenu="nodeContextmenu" @on-node-focus="handleFocus" @on-node-blur="handleBlur">
						<template slot-scope="{ node }">
							<slot :node="node">
								<div class="tree-org-node__text">
									<span>{{ node[keys.label] }}</span>
								</div>
							</slot>
						</template>
						<template v-slot:expand="{ node }">
							<slot name="expand" :node="node">
								<span class="tree-org-node__expand-btn">11</span>
							</slot>
						</template>
						<template v-slot:logic="{ node }">
							<slot name="logic" :node="node">
								<v-tooltip right>
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											<v-btn class="logic_btn" v-show="node.children.length > 1" dark width="20px" height="20px"
												:color="node.logic ? 'orange' : 'primary'" style="font-size: 10px;" @mousemove="andMousemove">
												{{ node.logic ? 'AND' : 'OR' }}
												<span class="arrow"></span>
												<span class="arrow"></span>
												<span class="arrow"></span>
												<span class="arrow"></span>
											</v-btn>
										</span>
									</template>
									<span>点击切换AND|OR</span>
								</v-tooltip>


							</slot>
						</template>
					</tree-org-node>
				</div>
			</zm-draggable>
			<!-- <div v-nodedrag>11111</div> -->
		</div>
		<template v-if="tools">
			<div class="zm-tree-handle">
				<div v-if="tools.scale" class="zm-tree-percent">{{ zoomPercent }}</div>
				<div v-if="tools.expand" @click="expandChange" :title="expandTitle" class="zm-tree-handle-item">
					<span class="zm-tree-svg">
						<!-- <i :class="['iconfont', expanded ? 'icon-collapse' : 'icon-expand']"></i> -->
						<v-icon v-if='expanded'>mdi-arrow-collapse-all</v-icon>
						<v-icon v-if='!expanded'>mdi-arrow-expand-all</v-icon>
						<!-- <img :src="svgUrl.expand" alt=""> -->
					</span>
				</div>
				<div v-if="tools.zoom" @click="enlargeOrgchart" title="放大" class="zm-tree-handle-item zoom-out">
					<span class="zm-tree-icon">+</span>
				</div>
				<div v-if="tools.zoom" @click="narrowOrgchart" title="缩小" class="zm-tree-handle-item zoom-in">
					<span class="zm-tree-icon">-</span>
				</div>
				<div v-if="tools.restore" @click="restoreOrgchart" title="还原" class="zm-tree-handle-item">
					<span class="zm-tree-restore"></span>
				</div>
				<div v-if="tools.fullscreen" @click="handleFullscreen" :title="fullTiltle" class="zm-tree-handle-item">
					<span class="zm-tree-svg">
						<i :class="['iconfont', fullscreen ? 'icon-unfullscreen' : 'icon-fullscreen']"></i>
						<v-icon v-if='fullscreen'>mdi-fullscreen-exit</v-icon>
						<v-icon v-if='!fullscreen'>mdi-overscan</v-icon>
						<!-- <img :src="svgUrl.fullscreen" alt=""> -->
					</span>
				</div>
			</div>
		</template>
		<clone-org v-if="nodeDraggable" v-show="nodeMoving" :props="keys" :data="cloneData" :horizontal="horizontal"
			:label-style="labelStyle" :collapsable="collapsable" :render-content="renderContent"
			:label-class-name="labelClassName">
			<template slot-scope="{ node }">
				<slot :node="node">
					<div class="tree-org-node__text">
						<span>{{ node[keys.label] }}</span>
					</div>
				</slot>
			</template>
			<template v-slot:expand="{ node }">
				<slot name="expand" :node="node">
					<span class="tree-org-node__expand-btn"></span>
				</slot>
			</template>
		</clone-org>
		<zm-contextmenu :visible.sync="contextmenu" :x="menuX" :y="menuY" :node="menuData" :data="data" :props="keys"
			:disabled="disabled" :node-add="nodeAdd" :node-delete="nodeDelete" :node-edit="nodeEdit" :node-copy="nodeCopy"
			@on-node-copy="(txt) => {$emit('on-node-copy', txt)}" @on-node-delete="(txt) => {$emit('on-node-delete', txt)}"
			@on-node-added="handleNodeAdded" />
	</div>
</template>

<script type="text/babel">
	import render from './node'
	import zmDraggable from '../zm-draggable'
	import cloneOrg from '../clone-org'
	import ZmContextmenu from '../contextmenu'
	import drag from '../../directives/drag'
	export default {
  name: 'ZmTreeOrg',
  components: {
    cloneOrg,
    zmDraggable,
    ZmContextmenu,
    TreeOrgNode: {
      render,
      functional: true,
    },
  },
  directives: {
    // 自定义指令
    nodedrag: drag,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    props: {
      type: Object,
      default: () => ({
        id: 'id',
        pid: 'pid',
        label: 'label',
        expand: 'expand',
        logic: 'logic',
        selected: 'selected',
        resolved: 'resolved',
        children: 'children',
      }),
    },
    toolBar: {
      type: [Object, Boolean],
      default: () => ({
        expand: true,
        scale: true,
        zoom: true,
        restore: true,
        fullscreen: true,
      }),
    },
    disabled: {
      // 是否禁用编辑
      type: Boolean,
      default: false,
    },
    draggable: {
      // 是否可拖拽移动位置
      type: Boolean,
      default: true,
    },
    draggableOnNode: {
      // 是否可拖拽节点移动位置
      type: Boolean,
      default: false,
    },
    nodeDraggable: {
      // 节点是否可拖拽
      type: Boolean,
      default: true,
    },
    cloneNodeDrag: {
      // 拷贝并拖拽节点
      type: Boolean,
      default: true,
    },
    onlyOneNode: {
      // 是否仅拖动当前节点
      type: Boolean,
      default: true,
    },
    clickDelay: {
      // 是否仅拖动当前节点
      type: Number,
      default: 260,
    },
    nodeDragStart: Function,
    nodeDraging: Function,
    nodeDragEnd: Function,
    horizontal: Boolean,
    selectedKey: String,
    collapsable: Boolean,
    renderContent: Function,
    labelStyle: Object,
    labelClassName: [Function, String],
    selectedClassName: [Function, String],
    nodeAdd: Function,
    nodeDelete: Function,
    nodeEdit: Function,
    nodeCopy: Function,
  },
  data() {
    return {
      treeData: {},
      selected: [],
      divwidth:0,
      divheight:0,
      marginleft:0,
      keys: {
        id: 'id',
        pid: 'pid',
        label: 'label',
        expand: 'expand',
        children: 'children',
      },
      tools: {
        expand: true,
        scale: true,
        zoom: true,
        restore: true,
        fullscreen: true,
      },
      autoDragging: false,
      scale: 1,
      left: 0,
      top: 0,
      expanded: false,
      fullscreen: false,
      nodeMoving: false,
      cloneData: {},
      copyText: '',
      contextmenu: false,
      menuData: {},
      menuX: 0,
      menuY: 0,
      timer: null,
      treeStyle: ''
    }
  },
  mounted: function() {
    let div = document.getElementById('divx')
    this.divwidth = div.offsetWidth
    this.divheight = div.offsetHeight
  },
  computed: {
    reasonTreeData() {
      return this.$store.state.triz.reasonTreeData
    },
    zoomPercent() {
      return `${Math.round(this.scale * 100)}%`
    },
    dragCancel() {
      return this.draggableOnNode || !this.nodeDraggable ? '' : '.tree-org-node-label'
    },
    expandTitle() {
      return this.expanded ? '收起全部节点' : '展开全部节点'
    },
    fullTiltle() {
      return this.fullscreen ? '退出全屏' : '全屏'
    },
    nodeargs() {
      return {
        drag: this.nodeDraggable,
        handleStart: this.nodeDragStart,
        handleMove: this.nodeDraging,
        handleEnd: this.nodeDragEnd,
      }
    },
  },
  watch: {
    horizontal() {
      // 改变架构图方向时，防止溢出边界
      this.$nextTick(() => {
        this.onDragStop(this.left, this.top)
      })
    },
    reasonTreeData: {
      handler() {
      this.$nextTick(this.adjustStyle)
    },
    immediate: true,
    deep: true
    }
  },
  created() {
    Object.assign(this.keys, this.props)
    if (typeof this.toolBar === 'object') {
      Object.assign(this.tools, this.toolBar)
    } else if (!this.toolBar) {
      this.tools = false
    }
  },
  
  methods: {
	  andMousemove(){
		  this.contextmenu = false
	  },
    adjustStyle() {
	  let divViewWidth = document.getElementsByClassName('zm-tree-org')[0].offsetWidth;
	  // divViewWidth=divViewWidth<980?980:divViewWidth
      let div = document.getElementById('divx')
      let divWidth = 0
      let divHeight = 0
      if(!div){
        divWidth = this.divwidth
        divHeight = this.divheight
      }else {
        divWidth = div.offsetWidth
        divHeight = div.offsetHeight
      }
      // console.log('获取宽度,高度',divWidth,divHeight)
      const { scale } = this
      // console.log('这里是',scale)
      let width = (divWidth / divViewWidth).toFixed(2)
      let height = ((divHeight / 800)+0.1).toFixed(2)
      const ratio = ((1 / width)>1?1:1 / width)
      let emitHeight = divHeight * ratio
	  console.log('adjustStyle',emitHeight)
      this.$emit('container-height-change', emitHeight + 40)
      console.log('这里是g宽度，高度',divViewWidth)
	  
      // let marginleft = (980-(divWidth*(ratio)))/2
	  let marginleft = (divViewWidth-(divWidth*(ratio)))/2
      this.treeStyle = {
        width: `${100 * width}%`,
        height: `${divHeight}px`,
        marginLeft:`${marginleft}px`,
        transform: `scale(${ratio})`,
      }
    },
    onDrag(x, y) {
      this.dragging = true
      this.autoDragging = false
      this.left = x
      this.top = y
      this.$emit('on-drag', { x, y })
    },
    onDragStop(x, y) {
      // 防止拖拽出边界
      this.dragging = false
      const zoom = this.$refs.zoom
      const orgchart = this.$refs['tree-org']
      const maxX = zoom.clientWidth / 2
      const maxY = zoom.clientHeight / 2
      let minY = zoom.clientHeight - orgchart.clientHeight
      let minX = zoom.clientWidth - orgchart.clientWidth
      if (minY > 0) {
        minY = 0
      }
      if (minX > 0) {
        minX = 0
      }
      if (x > maxX) {
        this.left = maxX
      } else if (x < minX) {
        this.left = minX
      } else {
        this.left = x
      }
      if (y < minY) {
        this.top = minY
      } else if (y > maxY) {
        this.top = maxY
      } else {
        this.top = y
      }
      this.$emit('on-drag-stop', { x, y })
    },
    nodeMouseenter(e, data) {
      if (this.nodeMoving) {
        this.parenNode = data
      }
      this.$emit('on-node-mouseenter', e, data)
	  
	  if(e.fromElement.localName=='div' && !e.fromElement._prevClass.includes('tree-org-node__children') ){
		this.nodeContextmenu(e, data)  
	  }else{
		  this.contextmenu = false
	  }
	  
    },
    nodeMouseleave(e, data) {
      if (this.nodeMoving) {
        this.parenNode = null
      }
      this.$emit('on-node-mouseleave', e, data)
    },
    nodeContextmenu(e, data) {
      e.stopPropagation()
      e.preventDefault()
      this.contextmenu = true
      this.menuX = e.clientX+20
      this.menuY = e.clientY
      this.menuData = data
    },
    zoomWheel(e) {
	  return
      e.preventDefault()
      // 鼠标滚轮缩放
      if (e.deltaY > 0) {
        this.narrowOrgchart()
      } else {
        this.enlargeOrgchart()
      }
      this.$emit('on-zoom', this.scale)
    },
    enlargeOrgchart() {
		console.log('enlargeOrgchart',this.scale)
      // 鼠标滚轮向上滚动放大
      if (Number(this.scale) < 3) {
        let scale = Number(this.scale) + 0.1
        this.scale = Number(scale).toFixed(1)
      }
    },
    narrowOrgchart() {
		console.log('narrowOrgchart',this.scale)
      // 鼠标滚轮向下滚动缩小
      if (Number(this.scale) > 0.3) {
        let scale = Number(this.scale) - 0.1
        this.scale = Number(scale).toFixed(1)
      }
    },
    restoreOrgchart() {
      this.scale = 1
      this.left = 0
      this.top = 0
    },
    autoDrag(el, left, top) {
      // 计算偏移量，保持根节点相对页面位置不变
      this.autoDragging = true
      this.dragging = false
      const x = el.offsetLeft - left
      const y = el.offsetTop - top
      this.left -= x
      this.top -= y
    },
    handleClick(e, data) {
      // 取消上次延时未执行的方法
      clearTimeout(this.timer)

      //执行延时
      this.timer = setTimeout(() => {
        //此处为单击事件要执行的代码
        this.$emit('on-node-click', e, data)
      }, this.clickDelay)
    },
    handleDblclick(e, node) {
		console.log('handleDblclick',e)
      // 只能选中叶节点
      // if (node.children && node.children.length) return
      // 取消上次延时未执行的方法
      clearTimeout(this.timer)
      this.$set(node, 'selected', !node.selected)

      // 处理解决关系
      this.refreshResolve()

      //此处为单击事件要执行的代码
      this.$emit('on-node-dblclick', e, node)
    },
    refreshResolve() {
      this.selected = []
      if (!this.data.children) return
      this.allSelectedNode(this.data.children)
      this.unresolveTree([this.data])
      this.resolveTree([this.data], this.selected, new Set())
    },
    allSelectedNode(children) {
      for (const node of children) {
        if (node.selected) this.selected.push(node)
        if (node.children && node.children.length) {
          this.allSelectedNode(node.children)
        }
      }
    },
    unresolveTree(tree) {
      for (const data of tree) {
        this.$set(data, 'resolved', false)
        if (data.children) {
          this.unresolveTree(data.children)
        }
      }
    },
    resolveTree(tree, selected, nodeSet) {
      if (!tree) return []
      if (!selected) return []
      for (const data of tree) {
        // 这里按照你的需求来存放最后返回的内容
        nodeSet.add(data)
        if (selected.some((ele) => ele.id === data.id)) {
          // 找到目标节点，获得一串父节点，从下向上遍历
          // console.log(nodeSet)
          for (const node of Array.from(nodeSet).reverse()) {
            // 叶节点不参与
            if (node.children && node.children.length) {
              if (node.selected) {
                continue
              }
              // 看它的子节点
              const selectedChildren = node.children.filter((child) => child.selected) // 选中数量
              const resolvedChildren = node.children.filter((child) => child.resolved) // 解决数量
              const okChildrenCount = selectedChildren.length + resolvedChildren.length
              if (node.logic) {
                // 如果是AND关系
                if (okChildrenCount >= 1) {
                  // 有任何子节点OK，则父节点OK
                  this.$set(node, 'resolved', true)
                }
              } else {
                // 如果是OR关系
                if (okChildrenCount >= node.children.length) {
                  // 认为子节点全部OK，则父节点OK
                  this.$set(node, 'resolved', true)
                }
              }
            }
          }
        }
        if (data.children) {
          const findChildren = this.resolveTree(data.children, selected, nodeSet)
          if (findChildren.length) return findChildren
        }
        nodeSet.delete(data)
      }
      return nodeSet
    },
    handleExpand(e, data) {
      e.stopPropagation()
      const el = document.querySelector('.is-root')
      const left = el.offsetLeft
      const top = el.offsetTop
      if ('expand' in data) {
        if (!data.expand && data.children) {
          this.collapse(data.children)
        }
      } else {
        this.$set(data, 'expand', true)
      }
      this.$nextTick(() => {
        this.autoDrag(el, left, top)
      })

      this.$emit('on-logic-change', e, data)
    },
    handleLogicChange(e, data) {
      e.stopPropagation()
      const el = document.querySelector('.is-root')
      const left = el.offsetLeft
      const top = el.offsetTop
      if ('logic' in data) {
        data.logic = !data.logic
      } else {
        this.$set(data, 'logic', true)
      }
      this.$nextTick(() => {
        this.autoDrag(el, left, top)
      })

      // 处理解决关系
      this.refreshResolve()

      this.$emit('on-logic-change', e, data)
    },
    handleNodeAdded(txt) {
      // 处理解决关系
      this.refreshResolve()
      this.$emit('on-node-added', txt)
    },
    handleFocus(e, data) {
      data.oldLabel = data.label
      this.$emit('on-node-focus', e, data)
    },
    handleBlur(e, data) {
      const { children, id, label } = this.keys
      const childNodes = this.menuData[children]
      for (let i = childNodes.length; i > 0; i--) {
        let item = childNodes[i - 1]
        if (item[id] == '' && item[label] == '') {
          childNodes.splice(i - 1, 1)
          break
        }
      }
      if (data.oldLabel !== data.label) {
        this.$emit('on-node-blur', e, data)
      }
    },
    handleFullscreen() {
      this.fullscreen = !this.fullscreen
      if (this.fullscreen) {
        this.launchIntoFullscreen()
      } else {
        this.exitFullscreen()
      }
    },
    launchIntoFullscreen() {
      // 全屏
      const element = this.$refs['zm-tree-org']
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    },
    exitFullscreen() {
      // 退出全屏
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    },
    collapse(list) {
      list.forEach((child) => {
        if (child.expand) {
          child.expand = false
        }
        child.children && this.collapse(child.children)
      })
    },
    expandChange() {
      this.expanded = !this.expanded
      this.toggleExpand(this.data, this.expanded)
      if (!this.expanded) {
        this.$nextTick(() => {
          this.onDragStop(this.left, this.top)
        })
      }
    },
    toggleExpand(data, val) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          this.$set(item, 'expand', val)
          if (item.children) {
            this.toggleExpand(item.children, val)
          }
        })
      } else {
        this.$set(data, 'expand', val)
        if (data.children) {
          this.toggleExpand(data.children, val)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>